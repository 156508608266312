.page_root__Q96Um {
    background-color: rgba(228, 81, 111, 0.075);
}
    [data-mantine-color-scheme='dark'] .page_root__Q96Um {
    background-color: rgba(254, 212, 221, 0.075);
}

.MantineCard_root__vWneI {
    background-color: rgba(255, 255, 255, 0.25);
}
    [data-mantine-color-scheme='dark'] .MantineCard_root__vWneI {
    background-color: rgba(50, 50, 50, 0.25);
}

